<template>
  <ApDialog
    :value="true"
    title="Write Rule"
    max-width="960"
    :actions="actions"
    @cancel="$emit('cancel')"
    @save="onSave"
  >
    <template #content>
      <div class="px-6 py-3 ap-black--text">
        <v-form ref="form">
          <div class="font-weight-medium text-body-2">Entity</div>
          <div class="d-flex">
            <v-radio-group
              v-model="entitySelected"
              row
              class="ma-0"
              hide-details
              @change="resetActions"
            >
              <v-radio
                v-for="item in writeRuleMetadata.entities"
                :key="item.name"
                :label="item.label"
                :value="item.name"
                hide-details
                class="ma-0 mr-3"
              />
            </v-radio-group>
          </div>

          <div class="font-weight-medium text-body-2 mt-3 mb-1">Actions</div>
          <v-card
            v-for="(action, index) in actionsSelected"
            :key="index"
            outlined
            class="px-3 py-2 mb-2"
          >
            <div class="font-weight-medium text-caption mb-1">
              {{ index + 1 }} action
            </div>

            <div class="d-flex align-center">
              <v-select
                v-model="action.name"
                :items="
                  entitySelected
                    ? writeRuleMetadata.actionsByEntity[entitySelected]
                    : []
                "
                :rules="mandatoryRule"
                item-value="name"
                item-text="label"
                placeholder="Action"
                outlined
                dense
                hide-details
              />

              <div class="d-flex pl-3">
                <v-icon @click="removeAction(index)">$mdi-delete</v-icon>
              </div>
            </div>

            <div
              v-if="action.name === 'convert'"
              class="font-weight-medium text-body-2 mt-1"
            >
              <v-checkbox
                v-model="action.createOpportunity"
                color="primary"
                class="mt-1 mb-3 pt-0"
                hide-details
                dense
              >
                <template #label>
                  <span class="text-caption" style="margin-left: -6px">
                    Convert Opportunity
                  </span>
                </template>
              </v-checkbox>

              <v-select
                v-model="action.convertedStatus"
                outlined
                dense
                hide-details
                :items="writeRuleMetadata.convertedStatus"
                item-value="name"
                item-text="label"
                label="Converted Status"
              />

              <div class="my-2 text-caption font-weight-medium">When</div>

              <div
                v-for="(groupConditions, andIndex) in action.conditions"
                :key="`group_conditions_${andIndex}`"
              >
                <div
                  v-if="andIndex > 0"
                  class="text-caption font-weight-medium py-4"
                >
                  OR
                </div>
                <v-card outlined class="px-3 py-2">
                  <div
                    v-for="(
                      whenCondition, whenConditionIndex
                    ) in groupConditions"
                    :key="`when_column_${whenConditionIndex}`"
                    class="d-flex mb-1"
                  >
                    <v-row dense>
                      <v-col cols="4">
                        <div class="d-flex">
                          <div
                            v-if="whenConditionIndex > 0"
                            class="d-flex align-center text-caption font-weight-medium px-2"
                          >
                            AND
                          </div>

                          <v-autocomplete
                            v-model="whenCondition.field"
                            :items="writeRuleMetadata.convertConditions"
                            :rules="mandatoryRule"
                            label="Field"
                            item-value="name"
                            item-text="label"
                            outlined
                            dense
                            hide-details
                            return-object
                          />
                        </div>
                      </v-col>

                      <v-col cols="4">
                        <v-select
                          v-if="whenCondition.field"
                          v-model="whenCondition.operator"
                          :items="
                            whenCondition.field
                              ? whenCondition.field.operators
                              : []
                          "
                          :rules="mandatoryRule"
                          label="Operator"
                          item-value="name"
                          item-text="label"
                          outlined
                          dense
                          hide-details
                          return-object
                        />
                      </v-col>

                      <v-col cols="4">
                        <v-text-field
                          v-if="whenCondition.operator"
                          v-model="whenCondition.value"
                          :rules="mandatoryRule"
                          dense
                          outlined
                          label="Value"
                          hide-details
                        />
                      </v-col>
                    </v-row>

                    <div class="d-flex align-center justify-center pl-3">
                      <v-icon
                        @click="
                          removeCondition(index, andIndex, whenConditionIndex)
                        "
                      >
                        $mdi-delete
                      </v-icon>
                    </div>
                  </div>

                  <v-btn
                    outlined
                    x-small
                    @click="addAndCondition(index, andIndex)"
                  >
                    <v-icon left small>$mdi-plus</v-icon>And
                  </v-btn>
                </v-card>
              </div>

              <v-btn
                outlined
                x-small
                class="mt-3"
                @click="addOrCondition(index)"
              >
                <v-icon left small>$mdi-plus</v-icon>
                {{ action.conditions.length > 0 ? 'Or' : 'Add condition' }}
              </v-btn>
            </div>
          </v-card>

          <v-btn outlined x-small @click="addAction">
            <v-icon left small>$mdi-plus</v-icon>Add action
          </v-btn>
        </v-form>
      </div>
    </template>
  </ApDialog>
</template>

<script>
import ApDialog from '@/components/common/ApDialog'
import { mapState } from 'vuex'

export default {
  components: {
    ApDialog,
  },
  props: {
    rule: {
      type: Object,
      default: () => {
        return {
          entity: null,
          actions: [],
        }
      },
    },
  },
  data() {
    return {
      entitySelected: null,
      actionsSelected: [],
      mandatoryRule: [(v) => (v !== undefined && v !== null) || ''],
    }
  },
  computed: {
    ...mapState('business-rules', {
      writeRuleMetadata: (state) => state.rulesMetadata.Write,
    }),
    actions() {
      var actions = [
        {
          event: 'cancel',
          label: 'Cancel',
          color: 'primary',
          outlined: true,
        },
        {
          event: 'save',
          label: 'Done',
          color: 'primary',
        },
      ]

      return actions
    },
  },
  created() {
    if (this.rule.entity) {
      this.entitySelected = this.rule.entity
      this.actionsSelected = this.rule.actions.map((el) => {
        const action = {
          name: el.name,
          createOpportunity: el.createOpportunity,
          convertedStatus: el.convertedStatus,
          conditions: el.conditions?.map((conditions) => {
            return conditions.map((condition) => {
              const field = this.writeRuleMetadata.convertConditions.find(
                (convertCondition) => convertCondition.name === condition.field
              )
              const operator = field?.operators.find(
                (operator) => (operator.name = condition.operator)
              )
              return {
                field,
                operator,
                value: condition.value,
              }
            })
          }),
        }
        return action
      })
    } else {
      this.entitySelected = this.writeRuleMetadata.entities[0].name
      this.addAction()
    }
  },
  methods: {
    addAction() {
      this.actionsSelected.push({
        name: null,
        createOpportunity: false,
        convertedStatus: null,
        conditions: [],
      })
    },
    addAndCondition(actionIndex, andIndex) {
      const a = [...this.actionsSelected]
      a[actionIndex].conditions[andIndex].push({
        field: null,
        operator: null,
        value: null,
      })
      this.actionsSelected = a
    },
    addOrCondition(actionIndex) {
      const a = [...this.actionsSelected]
      a[actionIndex].conditions.push([
        {
          field: null,
          operator: null,
          value: null,
        },
      ])
      this.actionsSelected = a
    },
    removeCondition(index, orIndex, andIndex) {
      const allConditions = this.actionsSelected[index].conditions
      const conditionsFromOrIndex = allConditions[orIndex]
      conditionsFromOrIndex.splice(andIndex, 1)

      const a = this.actionsSelected
      if (conditionsFromOrIndex.length > 0) {
        a[index].conditions[orIndex] = conditionsFromOrIndex
      } else {
        allConditions.splice(orIndex, 1)
        a[index].conditions = allConditions
      }
      this.actionsSelected = a
    },
    onSave() {
      if (this.$refs.form.validate()) {
        this.$emit('save', {
          entity: this.entitySelected,
          actions: this.actionsSelected.map((action) => {
            const rule = {
              name: action.name,
            }

            if (action.name === 'convert') {
              rule.createOpportunity = action.createOpportunity
              rule.convertedStatus = action.convertedStatus
              rule.conditions = action.conditions?.map((conditions) => {
                return conditions.map((condition) => {
                  return {
                    field: condition.field.name,
                    operator: condition.operator.name,
                    value: condition.value,
                  }
                })
              })
            }

            return rule
          }),
        })
      }
    },
    resetActions() {
      this.actionsSelected = [
        {
          name: null,
          createOpportunity: false,
          convertedStatus: null,
          conditions: [],
        },
      ]
    },
    removeAction(index) {
      const actions = this.actionsSelected
      actions.splice(index, 1)
      this.actionsSelected = actions
    },
  },
}
</script>
