<template>
  <ApLoading v-if="isLoading" class="mt-10" text="Loading Write Rule..." />

  <RulesStatusError v-else-if="isError" @click="getWriteRule" />

  <div v-else class="pa-4 mx-auto" style="max-width: 600px">
    <div class="text-h5 font-weight-black mb-2">
      How to apply changes in the CRM?
    </div>

    <WriteRuleList
      :items="conditions"
      class="mx-auto"
      @edit="onEdit"
      @delete="onDelete"
    />

    <div class="d-flex justify-end">
      <v-btn
        text
        block
        outlined
        small
        class="text-none font-weight-regular"
        @click="onAddNew"
      >
        <v-icon left>$mdi-plus</v-icon>
        New Entity
      </v-btn>
    </div>

    <WriteRuleForm
      v-if="isCriteriaDialogVisible"
      :rule="selectedCriteria"
      @save="onSave"
      @cancel="onCancel"
    />
  </div>
</template>

<script>
import ApLoading from '@/components/common/ApLoading'
import RulesStatusError from './RulesStatusError'
import { RULE_TYPES } from '@/api/business-rules/rules'
import { mapState, mapActions } from 'vuex'
import { cloneDeep, isEqual } from 'lodash-es'
import WriteRuleList from './WriteRuleList'
import WriteRuleForm from './WriteRuleForm'
import Vue from 'vue'

export default {
  components: {
    ApLoading,
    RulesStatusError,
    WriteRuleList,
    WriteRuleForm,
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      criteriaSelectedIndex: null,
      isCriteriaDialogVisible: false,
      conditions: [],
      originalConditions: null,
      entities: [],
    }
  },
  computed: {
    ...mapState('business-rules', {
      multiMapRule: (state) => state.directorRule.rules.MultiMap,
      isTriggeringSave: (state) =>
        state.directorRule.rules.Write?.isTriggeringSave,
      directorRule: (state) => state.directorRule,
      writeRuleMetadata: (state) => state.rulesMetadata.Write,
      writeRule: (state) => state.directorRule.rules.Write,
    }),
    selectedCriteria() {
      if (this.criteriaSelectedIndex === null) {
        return undefined
      }

      return this.conditions[this.criteriaSelectedIndex]
    },
  },
  watch: {
    async isTriggeringSave(value) {
      if (value) {
        try {
          if (!isEqual(this.originalConditions, this.conditions)) {
            if (this.writeRule.id) {
              await this.updateWriteRule({
                rule: this.writeRule,
                conditions: this.conditions,
              })
            } else {
              await this.createWriteRule({
                directorRuleId: this.$route.params.directorRuleId,
                rule: this.writeRule,
                conditions: this.conditions,
              })
            }
          }

          this.$emit('saved')
        } finally {
          this.resetTriggerSaveCurrentRule(this.writeRule.type)
        }
      }
    },
  },
  created() {
    this.getWriteRule()
  },
  methods: {
    ...mapActions('business-rules', [
      'getRule',
      'getRuleEntityMetadata',
      'assignLocalRuleToDirector',
      'resetTriggerSaveCurrentRule',
      'updateWriteRule',
      'createWriteRule',
    ]),
    async getWriteRule() {
      this.isLoading = true
      this.isError = false

      try {
        if (this.directorRule.rules?.Write?.id) {
          await this.getRule(this.directorRule.rules.Write.id)
          this.conditions = cloneDeep(this.writeRule.conditions)
          this.originalConditions = cloneDeep(this.writeRule.conditions)
        } else {
          const payload = {
            id: null,
            name: `${this.directorRule.name}: Write Rule`,
            description: 'Manually created Write rule',
            entities: 'Account',
            type: RULE_TYPES.write,
          }

          this.assignLocalRuleToDirector({ rule: payload })
        }

        this.entities = this.multiMapRule.entities

        const entities = this.entities.filter(
          (key) => !this.writeRuleMetadata?.[key]
        )

        if (entities.length > 0) {
          await this.getRuleEntityMetadata({
            entities: entities,
            ruleType: 'Write',
          })
        }
      } catch {
        this.isError = true
      } finally {
        this.isLoading = false
      }
    },
    onSave(rule) {
      if (this.criteriaSelectedIndex === null) {
        this.conditions = [...this.conditions, rule]
      } else {
        Vue.set(this.conditions, this.criteriaSelectedIndex, rule)
      }

      this.isCriteriaDialogVisible = false
      this.criteriaSelectedIndex = null
    },
    onCancel() {
      this.isCriteriaDialogVisible = false
    },
    onEdit(criteriaIndex) {
      this.criteriaSelectedIndex = criteriaIndex
      this.isCriteriaDialogVisible = true
    },
    onDelete(index) {
      this.conditions.splice(index, 1)
    },
    onAddNew() {
      this.criteriaSelectedIndex = null
      this.isCriteriaDialogVisible = true
    },
  },
}
</script>
