<template>
  <v-card outlined>
    <div class="d-flex align-center justify-space-between">
      <div class="d-flex align-center px-2 py-2">
        <v-icon class="handle mr-1"> $mdi-drag </v-icon>
        <div small class="font-weight-medium text-caption px-2 align-center">
          {{ item.entity }}
        </div>
      </div>

      <div class="d-flex mr-2">
        <v-btn
          text
          small
          outlined
          class="text-none font-weight-regular"
          @click.stop="$emit('delete')"
        >
          <v-icon left> $mdi-delete-outline </v-icon>
          Delete
        </v-btn>
      </div>
    </div>

    <div class="px-4 text-caption pb-3">
      <div>
        <span v-for="(action, index) in item.actions" :key="action.name">
          {{ action.name }}
          <v-icon v-if="index < item.actions.length - 1" small class="mx-1">
            $mdi-arrow-right-thin
          </v-icon>
        </span>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('business-rules', {
      rollUpRuleMetadata: (state) => state.rulesMetadata.RollUp,
    }),
  },
}
</script>
